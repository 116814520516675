<template>
  <div class="content-setting">
    <PageTitle
      title="編輯 Banner 區塊"
      icon="chevron_left"
      hideBtn
      @iconClick="$router.push({ name: 'PubAptContentSetting' })"
    />
    <section v-loading="loading" class="section-block card-container">
      <p class="title">Banner 輪播圖片</p>
      <BaseElForm ref="formRef" label-position="top" :model="formData" :rules="fomrRules">
        <BaseElFormItem prop="fullBannerSetting">
          <div class="">
            <div class="content-block-list">
              <div v-for="(block, idx) in contentList" :key="`${block.type}-${idx}`">
                <ImageBlockNoTitleEditBlock
                  v-if="block.type === 'image'"
                  :data.sync="contentList[idx]"
                  :ratio="[16,9]"
                  notice="圖片最大建議尺寸為 960 px * 540 px"
                  @remove="removeBlock(idx)"
                  @upLayer="moveData(idx, 'up')"
                  @downLayer="moveData(idx, 'down')"
                />
              </div>
            </div>
            <div class="flex items-center">
              <AddButton v-if="contentList.length < 5" content="新增圖片" @click="addBlock('imageNoTitle')" />
            </div>
          </div>
        </BaseElFormItem>
      </BaseElForm>
    </section>

    <PageFixedFooter @cancel="$router.push({ name: 'PubAptContentSetting' })" @confirm="submitCheck" />
  </div>
</template>

<script>
import PageTitle from '@/components/Title/PageTitle.vue'
import { defineComponent, onMounted, watch, ref } from 'vue'
import ImageBlockNoTitleEditBlock from '@/components/ImageBlockNoTitleEditBlock.vue'
import AddButton from '@/components/Button/AddButton.vue'
import notifyMessage from '@/config/notifyMessage'
import { useImageTextContent } from '@/use/useImageTextContent'
import { FindPubAptConfig, UpdatePubAptConfig } from '@/api/pubApt'
import { useShop } from '@/use/shop'
import { useRouter } from 'vue-router/composables'
import { map, get, omit } from 'lodash'
import { useBaseForm } from '@/use/useForm'

export default defineComponent({
  name: 'EditBannerSetting',
  components: {
    PageTitle,
    ImageBlockNoTitleEditBlock,
    AddButton,
  },
  setup (props, { emit }) {
    const router = useRouter()
    const { shopId } = useShop()
    const loading = ref(false)
    const fomrRules = {
      fullBannerSetting: [
        {
          validator: (rule, value) => {
            if (value.every(item => 'Image' in item)) {
              return Promise.resolve()
            } else {
              return Promise.reject(new Error('請上傳圖片'))
            }
          },
          trigger: ['blur', 'change'],
        },
      ],
    }
    const { contentList, addBlock, removeBlock, moveData } = useImageTextContent()
    const { formRef, formData, initFormData, checkForm } = useBaseForm()
    initFormData({
      fullBannerSetting: [],
    })
    watch(contentList, () => {
      formData.fullBannerSetting = contentList.value
    }, { deep: true })

    const findPubAptConfig = async () => {
      loading.value = true
      const [res, err] = await FindPubAptConfig({ shopId: shopId.value })
      if (err) {
        window.$message.error(err)
        return
      }
      formData.fullBannerSetting = res.fullBannerSetting || []
      contentList.value = formData.fullBannerSetting
      loading.value = false
    }
    const submitCheck = async () => {
      if (!(await checkForm(formRef.value))) {
        return
      }
      const [, err] = await UpdatePubAptConfig({
        shopId: shopId.value,
        data: {
          fullBannerSetting: map(contentList.value, (item) => {
            return {
              ImageId: get(item, 'Image.id'),
              type: 'image',
            }
          }),
        },
      })
      if (err) {
        window.$message.error(err)
        return
      }
      window.$message.success(notifyMessage.updateSuccess)
      router.push({ name: 'PubAptContentSetting' })
    }

    onMounted(async () => {
      await findPubAptConfig()
    })

    return { formData, fomrRules, formRef, contentList, addBlock, removeBlock, moveData, submitCheck, loading }
  },
})
</script>

<style scoped lang="postcss">
.title {
  @apply font-bold relative mb-[8px];
  &::after {
    content: '(建議上傳尺寸 16:9 )';
    @apply absolute left-[140px] top-[2px] text-sm text-gray-80;
  }
}
.content-block-list {
    @apply flex flex-col gap-[20px] mb-[12px];
}
</style>
